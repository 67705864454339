import React from 'react';
import './ComingSoon.scss';

const ComingSoon: React.FC = () => {
  return (
    <div className="coming-soon">
      <div className="coming-soon__content">
        <h1 className="coming-soon__content--title">Seed Money Consulting</h1>
        <p className="coming-soon__content--text">
          Our website is under construction. We'll be here soon with our new
          awesome site.
        </p>
      </div>
    </div>
  );
};

export default ComingSoon;
